const CandidateStatuses = {
    "Awaiting Client Data": 1,
    "Completed": 1,
    "Pending Manual Check": 2,
    "Pending F2F": 3,
    "Pending F2F Check": 3,
    "Rejected": 4,
    "Rejected, Cancelled Employment": 4,
    "Consent not given": 5,
    "Cancelled": 6,
}

export const getStatusMessage = (message) => {
    if (Object.keys(CandidateStatuses).indexOf(message) === -1)
        return 'errors.link-expired';

    return `rtw-status.${CandidateStatuses[message]}.description`;
}