// Help page specific
export const SELECT_ARTICLE = 'SELECT_ARTICLE';
export const SELECT_VIEW = 'SELECT_VIEW';
export const RESET_SELECTED_ARTICLE = 'RESET_SELECTED_ARTICLE';
export const UPDATE_SEARCH_TEXT = 'UPDATE_SEARCH_TEXT';

// Regular actions
export const DECREMENT_INDEX = 'DECREMENT_INDEX';
export const INCREMENT_INDEX = 'INCREMENT_INDEX';
export const INCREMENT_SKIP_PAGE = 'INCREMENT_SKIP_PAGE';
export const DECREMENT_SKIP_PAGE = 'DECREMENT_SKIP_PAGE';
export const SELECT_DOCUMENT_COMBINATION = 'SELECT_DOCUMENT_COMBINATION';
export const UPDATE_DOCUMENT_INDEX = 'UPDATE_DOCUMENT_INDEX';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const SET_PROMPTS = 'SET_PROMPTS';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_MESSAGE = 'SET_LOADING_MESSAGE';
export const SET_NAVIGATION_BLOCK = 'SET_NAVIGATION_BLOCK';
export const SET_NO_DOCUMENTS_AVAILABLE = 'SET_NO_DOCUMENTS_AVAILABLE';
export const SET_SESSION_EXPIRED = 'SET_SESSION_EXPIRED';
export const SET_SIDEBAR = 'SET_SIDEBAR';
export const UPDATE_INDEX_RESULTS = 'UPDATE_INDEX_RESULTS';
export const UPDATE_SUCCESS_SCREEN = 'UPDATE_SUCCESS_SCREEN';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS';
export const SET_REPORT_ERRORS = 'SET_REPORT_ERRORS';
export const UPDATE_VENDOR_DETAILS = 'UPDATE_VENDOR_DETAILS';
export const SET_START_TIME = 'SET_START_TIME';
export const JUMP_TO_BIOMETRIC_STEP = 'JUMP_TO_BIOMETRIC_STEP';
export const SET_APPLICATION_ONLINE = "SET_APPLICATION_ONLINE";
export const UPDATE_UUID = 'UPDATE_UUID';