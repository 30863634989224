import stepIndex from '../../consts/stepIndex';
import {
    INCREMENT_INDEX,
    DECREMENT_INDEX,
    UPDATE_INDEX_RESULTS,
    SELECT_DOCUMENT_COMBINATION,
    UPDATE_DOCUMENT_INDEX,
    SET_INITIAL_STATE,
    SET_LOADING,
    SET_LOADING_MESSAGE,
    SET_NAVIGATION_BLOCK,
    SET_NO_DOCUMENTS_AVAILABLE,
    SET_SESSION_EXPIRED,
    SET_SIDEBAR,
    UPDATE_USER,
    SET_LANGUAGE,
    INCREMENT_SKIP_PAGE,
    DECREMENT_SKIP_PAGE,
    UPDATE_DOCUMENTS,
    SET_REPORT_ERRORS,
    UPDATE_VENDOR_DETAILS,
    SET_START_TIME,
    JUMP_TO_BIOMETRIC_STEP,
    SET_PROMPTS,
    SET_APPLICATION_ONLINE,
    UPDATE_UUID
} from '../actions/actionTypes';

const UserReducer = (state, action) => {
    switch (action.type) {
        case INCREMENT_INDEX:
            return {
                ...state,
                onboardingIndex: state.onboardingIndex + 1,
            };
        case DECREMENT_INDEX:
            return {
                ...state,
                onboardingIndex: state.onboardingIndex - 1,
            };
        case INCREMENT_SKIP_PAGE:
            return {
                ...state,
                onboardingIndex: state.onboardingIndex + 2,
            };
        case DECREMENT_SKIP_PAGE:
            return {
                ...state,
                onboardingIndex: state.onboardingIndex - 2,
            };
        case UPDATE_INDEX_RESULTS:
            return {
                ...state,
                isLoading: false,
                onboardingIndex: stepIndex.RESULTS_STEP,
                result: action.result,
            };
        case JUMP_TO_BIOMETRIC_STEP:
            return {
                ...state,
                onboardingIndex: stepIndex.BIOMETRICS_CAPTURE_STEP,
            };
        case SELECT_DOCUMENT_COMBINATION:
            return {
                ...state,
                isLoading: false,
                onboardingIndex: state.onboardingIndex + 1,
                selectedDocumentCombination: action.selectedDocumentCombination,
            };
        case UPDATE_DOCUMENT_INDEX:
            return {
                ...state,
                documentIndex: action.documentIndex
            };
        case SET_INITIAL_STATE:
            return {
                ...state,
                countries: action.countries,
                isLoading: action.isLoading,
                user: action.user,
                uuid: action.uuid,
            };
        case SET_LANGUAGE:
            return {
                ...state,
                currentLanguage: action.currentLanguage,
                sidebarStatus: action.sidebarStatus,
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case SET_LOADING_MESSAGE:
            return {
                ...state,
                loadingMessage: action.loadingMessage,
            };
        case SET_NAVIGATION_BLOCK:
            return {
                ...state,
                isNavigationBlocked: action.isNavigationBlocked,
            };
        case SET_NO_DOCUMENTS_AVAILABLE:
            return {
                ...state,
                isNoDocumentsAvailableFlow: action.isNoDocumentsAvailableFlow,
            };
        case SET_SESSION_EXPIRED:
            return {
                ...state,
                isSessionExpired: action.isSessionExpired,
            };
        case SET_SIDEBAR:
            return {
                ...state,
                sidebarStatus: action.sidebarStatus,
            };
        case SET_PROMPTS:
            return {
                ...state,
                prompts: action.prompts,
                isLoading: action.isLoading,
            };
        case UPDATE_USER:
            return {
                ...state,
                isLoading: action.isLoading,
                isNavigationBlocked: false,
                user: action.user,
                vendor: action.vendor,
                documentCombinations: action.documentCombinations,
            };
        case UPDATE_DOCUMENTS:
            return {
                ...state,
                documentCombinations: action.documentCombinations,
                isLoading: action.isLoading,
            };
        case SET_REPORT_ERRORS:
            return {
                ...state,
                reportErrors: action.reportErrors
            }
        case UPDATE_VENDOR_DETAILS:
            return {
                ...state,
                vendor: action.vendor
            }
        case SET_START_TIME:
            return {
                ...state,
                startTime: action.startTime
            }
        case SET_APPLICATION_ONLINE: {
            return {
                ...state,
                applicationOnline: action.applicationOnline
            }
        }
        case UPDATE_UUID: {
            return {
                ...state,
                uuid: action.uuid
            }
        }
        default:
            return state;
    }
};

export default UserReducer;
